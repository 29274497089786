import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import { useStyle } from "../../hooks/classify"
import * as defaultClasses from "./logo.module.css"

const Logo = ({ parentClasses }) => {
	const classes = useStyle(defaultClasses, parentClasses)

	const data = useStaticQuery(graphql`
		query siteLogo {
			sanitySiteSettings {
				title
				siteLogo {
					asset {
						url
						metadata {
							dimensions {
								aspectRatio
							}
						}
					}
				}
			}
		}
	`)

	return (
		<Link to="/" className={classes.logoWrapper}>
			<img
				src={data?.sanitySiteSettings?.siteLogo?.asset?.url}
				alt={data?.sanitySiteSettings?.title}
				className={classes.logo}
			/>
		</Link>
	)
}

export default Logo
