import { useEffect, useCallback } from "react"

const ContentProtection: React.FC = () => {
	const isProduction = process.env.NODE_ENV === "production"

	const handleContextMenu = useCallback((event: MouseEvent) => {
		event.preventDefault()
	}, [])

	const handleKeyDown = useCallback((event: KeyboardEvent) => {
		// Prevent Ctrl+S, Cmd+S
		if (
			(event.ctrlKey || event.metaKey) &&
			event.key.toLowerCase() === "s"
		) {
			event.preventDefault()
		}
		// Prevent Ctrl+U, Cmd+U (View Source)
		if (
			(event.ctrlKey || event.metaKey) &&
			event.key.toLowerCase() === "u"
		) {
			event.preventDefault()
		}
		// Prevent Ctrl+Shift+I, Cmd+Option+I (Developer Tools)
		if (
			(event.ctrlKey || event.metaKey) &&
			event.shiftKey &&
			event.key.toLowerCase() === "i"
		) {
			event.preventDefault()
		}
	}, [])

	const handleCopy = useCallback((event: ClipboardEvent) => {
		event.preventDefault()
	}, [])

	useEffect(() => {
		if (!isProduction) return // Only run in production

		document.addEventListener("contextmenu", handleContextMenu)
		document.addEventListener("keydown", handleKeyDown)
		document.addEventListener("copy", handleCopy)

		// Disable drag and drop for images
		const disableDragStart = (event: DragEvent) => {
			if (event.target instanceof HTMLImageElement) {
				event.preventDefault()
			}
		}
		document.addEventListener("dragstart", disableDragStart)

		// Disable text selection
		const disableSelection = (e: Event) => {
			e.preventDefault()
		}
		document.addEventListener("selectstart", disableSelection)

		// Add CSS to prevent selection and dragging
		const style = document.createElement("style")
		style.textContent = `
      body {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
      }
    `
		document.head.appendChild(style)

		return () => {
			document.removeEventListener("contextmenu", handleContextMenu)
			document.removeEventListener("keydown", handleKeyDown)
			document.removeEventListener("copy", handleCopy)
			document.removeEventListener("dragstart", disableDragStart)
			document.removeEventListener("selectstart", disableSelection)
			document.head.removeChild(style)
		}
	}, [isProduction, handleContextMenu, handleKeyDown, handleCopy])

	return null
}

export default ContentProtection
